var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',[_vm._v(" Malla Curricular "+_vm._s(_vm.career ? String(_vm.$getVisibleNames("mesh.career", false, "Programa") + ": ") + _vm.career.name : "")+" ")]),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-3 mb-2 ml-auto btn noprint",attrs:{"variant":"primary"},on:{"click":_vm.DownloadCurricularMeshXLS}},[_c('b-icon-download',{staticClass:"mr-1 icon-download"}),_vm._v(" Malla Curricular XLS ")],1)],1),(_vm.egressProfile)?_c('div',{staticClass:"table-container"},[_c('table',[_c('thead',[(_vm.egressProfileCycleSort.length > 0)?_c('tr',[_c('td'),_vm._l((_vm.egressProfileCycleSort),function(cycle){return _c('td',{key:cycle.id,staticClass:"cycle-cell",attrs:{"colspan":_vm.cycleColspan(cycle)}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+" "+_vm._s(cycle.name)+" ")])})],2):_vm._e(),_c('tr',[_c('th'),(_vm.institution && _vm.institution.internal_use_id != 'duoc_uc')?[_vm._l((_vm.yearPerCycleSort.filter(
                (x) =>
                  _vm.semesterAmount.includes(x.init_semester) ||
                  _vm.semesterAmount.includes(x.end_semester)
              )),function(year){return [_c('th',{key:'year' + year.order,staticClass:"semester-cell",attrs:{"colspan":_vm.yearColspan(year)}},[_vm._v(" "+_vm._s(year.order)+"° Año ")])]})]:_vm._e()],2),_c('tr',[_c('th'),_vm._l((_vm.semesterAmount),function(index){return _c('th',{key:index,staticClass:"semester-cell"},[_vm._v(" "+_vm._s(index)+"° Nivel ")])})],2)]),_vm._l((_vm.formationAreasList),function(formation_area){return [(_vm.hideFormationArea(formation_area.id))?_c('FormationAreaRow',{key:formation_area.id,attrs:{"EgressProfile":_vm.egressProfile,"FormationArea":formation_area,"cycle_id":_vm.cycle_id}}):_vm._e()]}),(_vm.institution && _vm.institution.internal_use_id != 'ciisa_uss')?_c('tr',{staticClass:"total",staticStyle:{"margin-top":"1em !important"}},[_c('td',{staticClass:"totalTitle"},[_c('div',{},[_c('b',[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.credits", true, "Créditos SCT Totales" ).toUpperCase()))])])]),_vm._l((_vm.semesterAmount),function(index){return _c('td',{key:index},[(_vm.profileType)?_c('div',[_vm._v(" "+_vm._s(_vm.totalSCT(index))+" ")]):_vm._e()])})],2):_vm._e(),_c('tr',{staticClass:"total"},[_c('td',{staticClass:"totalTitle"},[[_c('b',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ).toUpperCase())+" ")])]],2),_vm._l((_vm.semesterAmount),function(index){return _c('td',{key:index},[[_vm._v(" "+_vm._s(_vm.totalPedagogical(index))+" ")]],2)})],2)],2)]):_vm._e(),[_c('div',{staticClass:"curricular-mesh-info page-break"},[_c('div',{staticClass:"formation-lines-container"},[_c('b-table',{staticClass:"mt-2 formation-lines-table",attrs:{"bordered":"","items":_vm.filteredFormationLines,"fields":_vm.formationLinesFields,"small":""},scopedSlots:_vm._u([{key:"cell(color)",fn:function(row){return [_c('div',{staticClass:"formation-line-color",style:({
                'background-color': row.item.color,
                color: row.item.color,
              })})]}}])}),_c('div',{staticClass:"footer-info"},[_vm._v(" * En caso de que la "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", false, "Asignatura"))+" no tenga "+_vm._s(String( _vm.$getVisibleNames( "mesh.formationline", false, "Línea De Formación" ) ))+", se mostrará de color "),_c('div',{staticClass:"formation-line-color",staticStyle:{"background-color":"#99c9d8","color":"#99c9d8"}})])],1),(_vm.filteredCertifications.length > 0 || _vm.allows_crud)?_c('div',{staticClass:"formation-lines-container"},[_c('b-table',{staticClass:"mt-2 ml-1 formation-lines-table",attrs:{"bordered":"","items":_vm.filteredCertifications,"fields":_vm.certifications_fields,"small":"","show-empty":""},scopedSlots:_vm._u([{key:"head(name)",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[(_vm.allows_crud)?_c('button-add',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_certification'),expression:"'mesh.add_certification'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Agregar ${_vm.$getVisibleNames(
                    'mesh.certification',
                    false,
                    'Certificación de Credenciales'
                  )}`
                ),expression:"\n                  `Agregar ${$getVisibleNames(\n                    'mesh.certification',\n                    false,\n                    'Certificación de Credenciales'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`new-certification-modal`)}}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.certification", true, "Certificaciones de Credenciales" ))+" ")])],1)]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" No hay datos para mostrar. ")]},proxy:true},{key:"cell(matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getMatterName(row.item.matters)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_certification'),expression:"'mesh.change_certification'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Editar ${_vm.$getVisibleNames(
                  'mesh.certification',
                  false,
                  'Certificación de Credenciales'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'mesh.certification',\n                  false,\n                  'Certificación de Credenciales'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-certification-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_certification'),expression:"'mesh.delete_certification'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Eliminar ${_vm.$getVisibleNames(
                  'mesh.certification',
                  false,
                  'Certificación de Credenciales'
                )}`
              ),expression:"\n                `Eliminar ${$getVisibleNames(\n                  'mesh.certification',\n                  false,\n                  'Certificación de Credenciales'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteCertification(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-certification-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
                'mesh.certification',
                false,
                'Certificación de Credenciales'
              )}`,"size":"lg","hide-footer":""}},[_c('CertificationForm',{attrs:{"Certification":row.item,"Career":_vm.career,"show_title":false},on:{"updated":function($event){return _vm.$bvModal.hide(`edit-certification-modal-${row.item.id}`)}}})],1)]}}:null],null,true)})],1):_vm._e(),(_vm.modalities.length > 0)?_c('div',{staticClass:"formation-lines-container"},[_c('b-table',{staticClass:"mt-2 ml-1 formation-lines-table",attrs:{"bordered":"","items":_vm.modalities,"fields":_vm.modalities_fields,"small":""},scopedSlots:_vm._u([{key:"cell(matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getMatterName(row.item.matters)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}}],null,false,3702611053)})],1):_vm._e(),(
          _vm.egress_profile_id &&
          _vm.profile_competences_mention.filter(
            (x) => x.egress_profile == _vm.egress_profile_id
          ).length > 0
        )?_c('EgressProfileCompetenceMention',{staticStyle:{"width":"400px"},attrs:{"allows_crud":false,"egress_profile_id":_vm.egress_profile_id,"curricular_mesh":true}}):_vm._e(),(_vm.coursesWrapperList.length > 0)?_c('div',{staticClass:"formation-lines-container"},[_c('b-table',{staticClass:"mt-2 formation-lines-table",attrs:{"bordered":"","items":_vm.coursesWrapperList,"fields":_vm.courses_wrapper_fields,"small":""},scopedSlots:_vm._u([{key:"cell(egress_profile_matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getEgressProfileMatterName(
                  row.item.egress_profile_matters
                )),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}}],null,false,3884152595)})],1):_vm._e(),_c('div',{staticClass:"d-flex matter-card-container"},[_c('div',{staticClass:"matter-card noprint"},[_c('div',{staticClass:"left-col"},[_c('div',{staticClass:"left-label",attrs:{"id":"matter-number-box"}},[_vm._v("1")]),_c('div',{staticClass:"left-label",attrs:{"id":"hour-modules-box"}},[_vm._v("2")]),_c('div',{staticClass:"left-label",attrs:{"id":"matter-credits-box"}},[_vm._v("3")]),_c('div',{staticClass:"left-label",attrs:{"id":"modalities-box"}},[_vm._v("4")]),_c('b-popover',{directives:[{name:"b-visible",rawName:"v-b-visible"}],attrs:{"target":"matter-number-box","placement":"topleft","triggers":"hover","content":`N° de orden de la ${_vm.$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`}}),_c('b-popover',{attrs:{"custom-class":"example-popover-1","target":"hour-modules-box","placement":"left","offset":-50,"triggers":"hover"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"hour-modules-popover-content"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" ")])]},proxy:true}])}),_c('b-popover',{attrs:{"custom-class":"example-popover-2","target":"matter-credits-box","placement":"left","triggers":"hover","offset":50,"content":`${this.$getVisibleNames(
                'manual.credits',
                true,
                'Créditos SCT'
              )}`}}),_c('b-popover',{attrs:{"target":"modalities-box","placement":"bottomleft","triggers":"hover","content":_vm.$getVisibleNames(
                  'mesh.modality',
                  false,
                  'Modalidad de Asignaturas'
                )}})],1),_c('div',{staticClass:"matter-title"},[_c('div',{staticClass:"matter-link"},[_c('div',{staticClass:"container-code-name"},[_c('b-badge',{staticClass:"code-matter mb-1",attrs:{"variant":"none","pill":""}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.matter_code", false, "Código"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+" ")])],1)])]),_c('div',{staticClass:"right-col"},[_c('div',{staticClass:"preq-label",attrs:{"id":"prerequisites-box"}},[_vm._v(" 5 "),_c('b-popover',{attrs:{"target":"prerequisites-box","triggers":"hover","placement":"top"}},[[_c('div',[_vm._v("Prerrequisitos")])]],2)],1),[_c('div',{staticClass:"comp-label",attrs:{"id":"competences-box"}},[[_c('div',[_vm._v("6")])],_c('b-popover',{attrs:{"target":"competences-box","triggers":"hover","placement":"righttop"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.competence", true, "Competencias" ))+" ")]},proxy:true}])})],2)]],2)]),_c('div',[_c('b-table',{staticClass:"ml-4 formation-lines-table noprint",attrs:{"items":_vm.matterCardList,"fields":_vm.matter_card_fields,"bordered":"","small":""}})],1)])],1)],_c('div',[_c('SumaryTableOfMatters',{staticClass:"page-break",attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud,"cycle_id":_vm.cycle_id}})],1),_c('b-modal',{attrs:{"id":`new-certification-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.certification',
      false,
      'Certificación de Credenciales'
    )}`,"size":"lg"}},[_c('CertificationForm',{attrs:{"Career":_vm.career},on:{"created":function($event){return _vm.$bvModal.hide('new-certification-modal')}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }